import './JobDetails.css';
const JobDetails = () => {
  return (
    <div className="JobDetails">
    <h1 className="Title">Available Vacancies</h1>
   
       <div> 
        <h3 className="JobTitle blue-text">Sales Account Manager</h3>
        <hr/>
        <h4 className="JobSubTitles">Role Description</h4>
        <p className="JobText">This is a full-time on-site role for a Sales Account Manager at Itqan Hub in Cairo, Egypt. The Sales Account Manager will be responsible for managing customer accounts, ensuring  satisfaction, generating leads, and providing excellent customer service daily.</p>
        <hr />
        <h4 className="JobSubTitles">Key Responsibility</h4>
        <ul className="JobBullets">
        <li>Identify new business opportunities and expand the client base.</li>
       <li>Manage the complete sales process, from generating leads to closing and securing deals.</li>
       <li>Contribute to strategic business development initiatives for short- and long-term growth.</li>
       <li>Submit clear, concise reports to track progress and measure success.</li>
       <li>Proactively address customer concerns to ensure exceptional and positive customer service.</li>
       <li>Generate leads by proactively reaching out to potential clients via phone and email to schedule meetings.</li>
       <li>Deliver sales presentations, send proposals and quotations, manage negotiations, and close deals to meet or exceed sales objectives.</li>
       <li>Achieve individual and team sales targets by maximizing every opportunity to close deals and contribute to exceeding KPIs.</li>
       <li>Monitor market dynamics, gather competitive insights, and provide regular reports on sales performance, customer feedback, and market trends.</li>
       </ul>
        <hr />
        <h4 className="JobSubTitles">Qualifications</h4>
        <ul className="JobBullets">
        <li>Lead Generation and Communication skills</li>
        <li> Proven track record of exceeding sales targets</li>
        <li> Strong negotiation and problem-solving skills</li>
        <li>Excellent interpersonal and communication skills</li> 
        <li>Ability to build and maintain client relationships </li>
        <li>In-depth understanding of sales in B2B context is a plus</li>
        <li> Knowledge of information technology and network security</li> 
        <li>Customer Satisfaction, Account Management, and Customer Service skills</li>
        <li>Minimum of 3 years of experience in training or ICT services sales is required</li>
        <li>Proficient in English, both verbal and written  Bachelor's degree in a related field</li></ul>
      </div>
      <h3 className="BottomText blue-text"> Interested candidates should submit their CV with the subject line " Sales Account Manager " to <a href="mailto:hr@itqanhub.com" className="green-text">hr@itqanhub.com</a></h3>
    </div>
  )
}

export default JobDetails
