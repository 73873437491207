import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <HelmetProvider>
  <BrowserRouter>
    <ScrollToTop/>
    <App />
  </BrowserRouter>
  </HelmetProvider>
  </>
);

reportWebVitals();
