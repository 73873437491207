
import { useEffect,useState } from 'react';
import BlogCardPreview from '../BlogCardPreview/BlogCardPreview';
import Placeholder from '../Placeholder/Placeholder';
import PlaceholderImg from '../../Images/Contact-us.svg';
import Loader from '../Loader/Loader';
import { Helmet } from 'react-helmet-async';
import './BlogPage.css';
const BlogPage = () => {
  const [blogs,setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading,setIsLoading] = useState(true);
  useEffect(() => { 
    fetch( `https://itqanhub.com/Apis/Blogs.php`,{
      method: 'GET',
      headers: {
       'Content-Type': 'application/json'
    },
    }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return response.json();
      })
      .then((fetchedData) => {
        setBlogs(fetchedData);
        setError(null); 
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  let blogsCards;
  if (blogs && blogs.length && !isLoading > 0 && !error) {
    blogsCards = blogs.map((blog) => (
    
      <BlogCardPreview key={blog.Id} blog={blog} />
    ));
  } else {
    blogsCards = (
       <Placeholder img={PlaceholderImg} text="No blogs available at the moment. Check back later!" />
    );
  }
  return (
    <div className="blog-page">
      <Helmet>
        <meta name="description" content="Itqan Hub | Stay Updated With Expert Tips,Industry Trends and Professional guidance to advance your IT Knowledge and Career"/>
        <meta name="keywords" content="IT training partners, certification programs, IT training providers, professional certifications, IT education, training partnerships, top IT training, certification partners, IT training programs, technology education partners" />
    </Helmet>
          <h1 className="Title">Blogs</h1>
          {isLoading &&  <Loader/>}
      <div className="blog-container">
     
     {blogsCards}
      </div>
    </div>
  )
}

export default BlogPage
