import FooterImg from '../../Images/ItqanHubSecondaryLogo3.png';
import './Footer.css';
const Footer = () => {
  return (
    <>
    <div className="Footer blue-bg">
    <div className="SocialLinks">
        <a href="https://www.facebook.com/ItqanHub/">
            <i className="fab fa-facebook"></i>
        </a>
      
        <a href="https://www.linkedin.com/company/itqan-hub/">
            <i className="fab fa-linkedin"></i>
        </a>
       
    </div>
    <img className="FooterLogo" src={FooterImg} alt="Footer Logo"/>
    <h5 className="CopyRightsText">COPYRIGHT &copy; {new Date().getFullYear()} ITQANHUB.COM-ALL RIGHTS RESERVED</h5>
    </div>
    </>
    
    
  )
}

export default Footer
